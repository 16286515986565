import axios from "axios";

const http = () => {
  let instance = axios.create({
    baseURL: process.env.REACT_APP_API,
  });

  return instance;
};

export default http();
