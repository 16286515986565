import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { valueNotEmpty } from "../../validators/blank";

export default function InputText({
  name,
  label,
  control,
  rules,
  errors,
  variant,
  defaultValue,
}) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          ...rules,
          validate: (value) => valueNotEmpty(value, control, name),
        }}
        defaultValue={defaultValue ? defaultValue : ""}
        render={({ field }) => (
          <TextField
            {...field}
            variant={variant ? variant : "outlined"}
            fullWidth
            autoComplete="off"
            error={errors ? true : false}
            required={rules && rules.required ? rules.required : false}
            label={label}
            helperText={
              errors && errors.type === "required"
                ? "Campo obrigatório"
                : errors && errors.type === "validate"
                ? "Campo em branco"
                : ""
            }
            type="text"
          />
        )}
      />
    </>
  );
}
